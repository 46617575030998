define("ember-modal-dialog/components/modal-dialog", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/string", "@ember/utils", "@ember/debug", "@embroider/util", "@ember/object/internals", "@ember/template-factory", "@embroider/macros/es-compat2"], function (_exports, _component, _component2, _service, _string, _utils, _debug, _util, _internals, _templateFactory, _esCompat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <this.whichModalDialogComponent
    @wrapperClass={{@wrapperClass}}
    @wrapperClassNames={{@wrapperClassNames}}
    @overlayClass={{@overlayClass}}
    @overlayClassNames={{@overlayClassNames}}
    @containerClass={{@containerClass}}
    @containerClassNames={{this.containerClassNamesVal}}
    @hasOverlay={{this.hasOverlay}}
    @translucentOverlay={{@translucentOverlay}}
    @clickOutsideToClose={{@clickOutsideToClose}}
    @destinationElementId={{this.destinationElementId}}
    @overlayPosition={{this.overlayPosition}}
    @tetherTarget={{@tetherTarget}}
    @legacyTarget={{@target}}
    @attachment={{@attachment}}
    @targetAttachment={{this.targetAttachment}}
    @targetModifier={{@targetModifier}}
    @targetOffset={{@targetOffset}}
    @offset={{@offset}}
    @tetherClassPrefix={{@tetherClassPrefix}}
    @constraints={{@constraints}}
    @attachmentClass={{this.attachmentClass}}
    @stack={{this.stack}}
    @value={{this.value}}
    @onClickOverlay={{this.onClickOverlayAction}}
    @onClose={{this.onCloseAction}}
    id={{this.stack}}
    ...attributes
  >
    {{yield}}
  </this.whichModalDialogComponent>
  */
  {
    "id": "qznvoCoe",
    "block": "[[[8,[30,0,[\"whichModalDialogComponent\"]],[[16,1,[30,0,[\"stack\"]]],[17,1]],[[\"@wrapperClass\",\"@wrapperClassNames\",\"@overlayClass\",\"@overlayClassNames\",\"@containerClass\",\"@containerClassNames\",\"@hasOverlay\",\"@translucentOverlay\",\"@clickOutsideToClose\",\"@destinationElementId\",\"@overlayPosition\",\"@tetherTarget\",\"@legacyTarget\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@targetOffset\",\"@offset\",\"@tetherClassPrefix\",\"@constraints\",\"@attachmentClass\",\"@stack\",\"@value\",\"@onClickOverlay\",\"@onClose\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,0,[\"containerClassNamesVal\"]],[30,0,[\"hasOverlay\"]],[30,7],[30,8],[30,0,[\"destinationElementId\"]],[30,0,[\"overlayPosition\"]],[30,9],[30,10],[30,11],[30,0,[\"targetAttachment\"]],[30,12],[30,13],[30,14],[30,15],[30,16],[30,0,[\"attachmentClass\"]],[30,0,[\"stack\"]],[30,0,[\"value\"]],[30,0,[\"onClickOverlayAction\"]],[30,0,[\"onCloseAction\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,17,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@wrapperClass\",\"@wrapperClassNames\",\"@overlayClass\",\"@overlayClassNames\",\"@containerClass\",\"@translucentOverlay\",\"@clickOutsideToClose\",\"@tetherTarget\",\"@target\",\"@attachment\",\"@targetModifier\",\"@targetOffset\",\"@offset\",\"@tetherClassPrefix\",\"@constraints\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-modal-dialog/components/modal-dialog.hbs",
    "isStrictMode": false
  });
  const VALID_OVERLAY_POSITIONS = ['parent', 'sibling'];

  // Args:
  // `hasOverlay`          | Toggles presence of overlay div in DOM
  // `translucentOverlay`  | Indicates translucence of overlay, toggles presence of `translucent` CSS selector
  // `onClose`               | The action handler for the dialog's `onClose` action. This action triggers when the user clicks the modal overlay.
  // `onClickOverlay`      | An action to be called when the overlay is clicked. If this action is specified, clicking the overlay will invoke it instead of `onClose`.
  // `clickOutsideToClose` | Indicates whether clicking outside a modal *without* an overlay should close the modal. Useful if your modal isn't the focus of interaction, and you want hover effects to still work outside the modal.
  // `renderInPlace`       | A boolean, when true renders the modal without wormholing or tethering, useful for including a modal in a style guide
  // `overlayPosition`     | either `'parent'` or `'sibling'`,  to control whether the overlay div is rendered as a parent element of the container div or as a sibling to it (default: `'parent'`)
  // `containerClass`      | CSS class name(s) to append to container divs. Set this from template.
  // `containerClassNames` | CSS class names to append to container divs. If you subclass this component, you may define this in your subclass.)
  // `overlayClass`        | CSS class name(s) to append to overlay divs. Set this from template.
  // `overlayClassNames`   | CSS class names to append to overlay divs. If you subclass this component, you may define this in your subclass.)
  // `wrapperClass`        | CSS class name(s) to append to wrapper divs. Set this from template.
  // `wrapperClassNames`   | CSS class names to append to wrapper divs. If you subclass this component, you may define this in your subclass.)
  // `animatable`          | A boolean, when `true` makes modal animatable using `liquid-fire` (requires `liquid-wormhole` to be installed, and for tethering situations `liquid-tether`. Having these optional dependencies installed and not specifying `animatable` will make `animatable=true` be the default.)
  // `tetherTarget`        | If you specify a `tetherTarget`, you are opting into "tethering" behavior, and you must have either `ember-tether` or `liquid-tether` installed.
  // `destinationElementId`| optional
  // `targetAttachment`    | Delegates to Hubspot Tether*
  // `tetherClassPrefix`   | Delegates to Hubspot Tether*
  // `offset`              | Delegates to Hubspot Tether*
  // `targetOffset`        | Delegates to Hubspot Tether*
  // `constraints`         | Delegates to Hubspot Tether*
  // `stack`               | Delegates to liquid-wormhole/liquid-tether
  // `value`               | pass a `value` to set a "value" to be passed to liquid-wormhole / liquid-tether
  let ModalDialog = _exports.default = (_dec = (0, _service.inject)('modal-dialog'), _class = class ModalDialog extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modalService", _descriptor, this);
      _defineProperty(this, "onCloseAction", () => {
        const {
          onClose
        } = this.args;
        // we shouldn't warn if the callback is not provided at all
        if (!onClose) {
          return;
        }
        (false && !((0, _utils.typeOf)(onClose) === 'function') && (0, _debug.assert)('onClose handler must be a function', (0, _utils.typeOf)(onClose) === 'function'));
        onClose();
      });
      _defineProperty(this, "onClickOverlayAction", ev => {
        ev.preventDefault();
        const {
          onClickOverlay
        } = this.args;
        // we shouldn't warn if the callback is not provided at all
        if (!onClickOverlay) {
          this.onCloseAction();
          return;
        }
        (false && !((0, _utils.typeOf)(onClickOverlay) === 'function') && (0, _debug.assert)('onClickOverlay handler must be a function', (0, _utils.typeOf)(onClickOverlay) === 'function'));
        onClickOverlay();
      });
    }
    get value() {
      // pass a `value` to set a "value" to be passed to liquid-wormhole / liquid-tether
      return this.args.value || 0;
    }
    get hasLiquidWormhole() {
      return this.modalService.hasLiquidWormhole;
    }
    get hasLiquidTether() {
      return this.modalService.hasLiquidTether;
    }
    get hasOverlay() {
      return this.args.hasOverlay ?? true;
    }
    get stack() {
      // this `stack` string will be set as this element's ID and passed to liquid-wormhole / liquid-tether
      return (0, _internals.guidFor)(this);
    }
    get containerClassNamesVal() {
      return this.args.containerClassNames || this.containerClassNames || null;
    }
    get attachmentClass() {
      let {
        attachment
      } = this.args;
      if (!attachment) {
        return undefined;
      }
      return attachment.split(' ').map(attachmentPart => {
        return `emd-attachment-${(0, _string.dasherize)(attachmentPart)}`;
      }).join(' ');
    }
    get targetAttachment() {
      return this.args.targetAttachment || 'middle center';
    }
    get whichModalDialogComponent() {
      let {
        hasLiquidTether,
        hasLiquidWormhole
      } = this;
      let {
        animatable,
        tetherTarget,
        renderInPlace
      } = this.args;
      let module = (0, _esCompat.default)(require("ember-modal-dialog/components/basic-dialog"));
      if (renderInPlace) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/in-place-dialog"));
      } else if (tetherTarget && hasLiquidTether && hasLiquidWormhole && animatable === true) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/liquid-tether-dialog"));
      } else if (tetherTarget) {
        this.ensureEmberTetherPresent();
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/tether-dialog"));
      } else if (hasLiquidWormhole && animatable === true) {
        module = (0, _esCompat.default)(require("ember-modal-dialog/components/liquid-dialog"));
      }
      return (0, _util.ensureSafeComponent)(module.default, this);
    }
    get destinationElementId() {
      return this.args.destinationElementId || this.modalService.destinationElementId;
    }
    validateProps() {
      let overlayPosition = this.overlayPosition;
      if (VALID_OVERLAY_POSITIONS.indexOf(overlayPosition) === -1) {
        (false && (0, _debug.warn)(`overlayPosition value '${overlayPosition}' is not valid (valid values [${VALID_OVERLAY_POSITIONS.join(', ')}])`, false, {
          id: 'ember-modal-dialog.validate-overlay-position'
        }));
      }
    }
    get overlayPosition() {
      let result = this.args.overlayPosition || 'parent';
      if (false /* DEBUG */ && VALID_OVERLAY_POSITIONS.indexOf(result) === -1) {
        (false && (0, _debug.warn)(`overlayPosition value '${result}' is not valid (valid values [${VALID_OVERLAY_POSITIONS.join(', ')}])`, false, {
          id: 'ember-modal-dialog.validate-overlay-position'
        }));
      }
      return result;
    }
    ensureEmberTetherPresent() {
      if (!this.modalService.hasEmberTether) {
        throw new Error('Please install ember-tether in order to pass a tetherTarget to modal-dialog');
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "modalService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalDialog);
});